import React from "react";
import "../styles/main.scss";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { motion } from "framer-motion";
import NavMobile from "../components/NavMobile";
import { SEO } from "../components/seo";
const profilaktyka = () => {
  return (
    <>
      <NavMobile />
      <Header isHome={false} />
      <main>
        <section className="section">
          <div className="section-wrapper">
            <div className="text-container text-container--full">
              <motion.h1
                viewport={{ once: true }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                className="title"
              >
                Profilaktyka
              </motion.h1>
              <motion.p
                viewport={{ once: true }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                Profilaktyka i edukacja to nasza kolejna misja. Realizowana w
                gabinecie Miladent przy ogromnym wsparciu przez profesjonalną
                dyplomowaną higienistkę. Głównym zadaniem profilaktyki
                stomatologicznej jest walka z przyczynami rozwoju próchnicy -
                osadem, kamieniem oraz płytką nazębną. Wspieranie naszych
                pacjentów w utrzymaniu dobrego stanu ich uzębienia oraz
                wykonanych prac protetycznych jest dla nas priorytetem.
                Oferujemy zabiegi usuwania kamienia i osadów nowoczesnym
                sprzętem, zaprojektowanym tak, by były one dla naszych pacjentów
                - szybsze, bezbolesne i jak najbardziej efektywne.
              </motion.p>
            </div>
          </div>
        </section>
      </main>
      <Footer isHome={false} />
    </>
  );
};

export default profilaktyka;
export const Head = () => <SEO title="Miladent - profilaktyka" />;
